<template>
	<!-- 会员中心 -->
	<div class="finished">
		<div class="project">
			<div class="query-area">
				<h3>保证金审核</h3>
				<el-form class="" :model="form" label-position="left" label-width="80px" inline ref="form">
					<el-form-item label="项目名称">
						<el-input style="width: 190px" v-model="form.xmmc" placeholder="项目名称"> </el-input>
					</el-form-item>
					<el-form-item label="项目编号">
						<el-input style="width: 190px" v-model="form.xmbh" placeholder="项目编号"> </el-input>
					</el-form-item>
					<el-form-item label="产权类别">
						<el-select style="width: 190px" v-model="form.cqlxbh">
							<el-option
								v-for="item in typeOptions"
								:key="item.label"
								:label="item.label"
								:value="item.value"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="流转类型">
						<el-select style="width: 190px" v-model="form.lzlx">
							<el-option :value="0" label="出租"></el-option>
							<el-option :value="1" label="入股"></el-option>
							<el-option :value="2" label="转让"></el-option>
							<el-option :value="3" label="出让"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="审核状态">
						<el-select style="width: 190px" v-model="form.auditStatus">
							<el-option label="未审核" :value="1"></el-option>
							<el-option label="通过" :value="3"></el-option>
							<el-option label="不通过" :value="4"></el-option>
						</el-select>
					</el-form-item>
					<div class="button" style="display: inline-block; margin: 0">
						<el-form-item>
							<el-button class="query-btn" type="primary" @click="query">查询</el-button>
							<el-button class="query-btn" type="primary" @click="clear">清空</el-button>
						</el-form-item>
					</div>
				</el-form>
			</div>
			<div class="table-con">
				<el-table
					border
					class="table"
					:data="tableData"
					style="width: 100%"
					:row-class-name="handleRowClass"
				>
					<el-table-column
						align="center"
						prop="xmbh"
						label="项目编号"
						width="120px"
					></el-table-column>
					<el-table-column
						align="center"
						prop="xmmc"
						label="项目名称"
						width="260px"
					></el-table-column>
					<el-table-column align="center" prop="bzj" label="保证金金额"></el-table-column>
					<el-table-column align="center" prop="jjjzsj" label="保证金截止时间"></el-table-column>
					<el-table-column align="center" prop="bzjscsj" label="上传时间"></el-table-column>
					<el-table-column align="center" prop="auditStatus" label="审核状态">
						<template slot-scope="scope">
							<span v-if="scope.row.auditStatus == '0'">未上传</span>
							<span v-else-if="scope.row.auditStatus == '1'">未审核</span>
							<span v-else-if="scope.row.auditStatus == '2'">审核中</span>
							<span v-else-if="scope.row.auditStatus == '3'">通过</span>
							<span v-else-if="scope.row.auditStatus == '4'">不通过</span>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="auditTime" label="审核时间"></el-table-column>
					<el-table-column align="left" label="操作" width="150">
						<template slot-scope="scope">
							<el-button
								v-if="scope.row.bmzt == '4'"
								style="width: 46px; height: 28px; padding: 0 8px"
								type="primary"
								size="small"
								@click="showDetail(scope.row)"
								>查 看</el-button
							>
							<el-button
								v-if="scope.row.bmzt != '4'"
								style="width: 46px; height: 28px; padding: 0 8px"
								type="primary"
								size="small"
								@click="examineBzj(scope.row)"
								>审 核</el-button
							>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagenation">
				<el-pagination
					background
					layout="total, prev, pager, next"
					:current-page="currentPage"
					:page-size="pageSize"
					:total="total"
					@current-change="currentChange"
				>
				</el-pagination>
			</div>
		</div>

		<examineBzj
			:visible="dialogVisible"
			@close="dialogClose"
			:examineData="examineData"
		></examineBzj>
	</div>
</template>

  <script>
import { mapGetters } from 'vuex'
import { queryBmjlAuditByPg } from '@/api/projectManage'
// import { getAreaTree } from '@/api/certificate'
import { getBzjCheckResult } from '@/api/exchange'
import examineBzj from './examineBzj.vue'
export default {
	components: {
		examineBzj,
	},
	data() {
		return {
			form: {
				xmmc: null,
				cqlxbh: null,
				jjzt: null,
				lzlx: null,
				xmbh: null,
				xmmc: null,
				auditStatus: null,
			},
			typeOptions: [],
			regionOptions: [],
			props: {
				label: 'areaName',
				children: 'children',
				value: 'id',
				fullPathName: 'fullPathName',
				checkStrictly: true,
				emitPath: false,
			},
			tableData: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			examineData: {},
			dialogVisible: false,
		}
	},
	computed: mapGetters(['userInfo', 'identityObj']),
	created() {
		this.initData()
		this.getTypes()
	},
	methods: {
		async initData() {
			// 获取数据
			let params = Object.assign(this.form, {
				current: this.currentPage,
				size: this.pageSize,
				xzqbh: this.identityObj.areaCode,
			})
      // console.log(params,'查看')
			let res = await queryBmjlAuditByPg(params)
			let { data, status, message } = res
			if (status == 200) {
				this.tableData = data.records
				this.total = parseInt(data.total)
			}
		},
    // 条件查询列表
		query() {
			this.currentPage = 1
			this.initData()
		},
    // 清空查询条件
		clear() {
			this.currentPage = 1
			this.form = {
				xmmc: null,
				cqlxbh: null,
				jjzt: null,
				lzlx: null,
				xmbh: null,
				xmmc: null,
				auditStatus: null,
			}
			this.initData()
		},
		handleRowClass({ row, rowIndex }) {
			// 行樣式
			if (rowIndex % 2 === 0) {
				return 'odd'
			}
		},
    // 获取产权类型
		getTypes() {
			let cqList = JSON.parse(localStorage.getItem('types'))
			this.typeOptions = cqList.map(x => {
				return {
					label: x.lx,
					value: x.bh,
				}
			})
		},
		showDetail(row) {},
		dialogClose(val) {
			// 关闭弹窗
			this.dialogVisible = val
			this.initData()
		},
    // 切换页码
		currentChange(val) {
			this.currentPage = val
			this.initData()
		},
    // 保证金审核
		async examineBzj(row) {
			let resBzj = await getBzjCheckResult({ bzjId: row.bzjId })
			if (resBzj.status == 200 && resBzj.rel) {
				this.examineData = resBzj.data
				this.examineData.bzjUrl =
					window.globalUrl.HOME_API + 'admin/file/get?ossFilePath=' + this.examineData.bzjPz
				this.dialogVisible = true
			} else {
				this.$message.error(resBzj.message)
			}
		},
	},
}
</script>

  <style lang="less" scoped>
.query-area {
	padding: 0 30px;
}
.finished {
	text-align: left;
	height: 100%;
	position: relative;
	// margin-top: 46px;
	background: #fff;

	.s-title {
		.title {
			margin-left: 10px;
			font-size: 15px;
			font-weight: 600;
		}
	}
	.project {
		.table-con {
			margin-top: 12px;
			padding: 0 15px 0 18px;
		}
		.tip {
			padding-left: 20px;
			color: #e92f00;
			margin-bottom: 20px;
		}
	}
	.news {
		margin-top: 33px;
	}
	.pagenation {
		// margin-top: 0;
		// position: absolute;
		// right: 20px;
		// bottom: 33px;
		text-align: right;
	}
}
</style>

  <style lang="less">
.finished {
	min-height: 1080px;
	.el-dialog__wrapper {
		position: absolute;
	}
	.v-modal {
		position: absolute;
	}
	.el-checkbox-button__inner {
		width: 160px;
		border-left: 1px solid #dcdfe6;
	}
	.el-dialog--center .el-dialog__body {
		padding: 25px 60px 30px 38px;
	}
	.el-checkbox-button.is-checked .el-checkbox-button__inner {
		border-radius: 5px;
		border: solid 1px #00a0e9;
		background: #fff;
		color: #00a0e9;
	}
	.el-dialog__footer {
		padding: 10px 20px 45px;
	}
}
</style>
